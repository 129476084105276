import moment from "moment";
import UserData from "../custom-hook/use-user";
import momentTimezone from "moment-timezone"
export function isEmailValid(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}

export function isStrongPassword(password) {
    // const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*_]).{8,}$/;

    return regex.test(password);
}
export function isPhoneNumber(input) {
    const regex = /^\+\d+$/;
    return regex.test(input);
}

export function getActiveUser(authUser){
    return authUser.users[0]
}
export function validateIPAddress(ipAddress) {
    const ipAddressRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

    return ipAddressRegex.test(ipAddress);
}

export function getCompanyType(type){
    // let type=company?.companyType || ""
    // return company?.companyName || ""
    switch (type){
        case "V":
            return "Variablegrid"
        case "R":
            return "Customer"
        case "C":
            return "Contractor"
        case "G":
            return "Group"
        case "D":
            return "Dealer"
        default:
            return ""
    }

}
export function getCompanyTypeObj(type){
    switch (type){
        case "V":
            return {companyName:"Variablegrid",companyId:"V"}
        case "R":
            return {companyName:"Customer",companyId:"R"}
        case "C":
            return {companyName:"Contractor",companyId:"C"}
        case "G":
            return {companyName:"Group",companyId:"G"}
        case "D":
            return {companyName:"Dealer",companyId:"D"}
        default:
            return {companyName:"",companyId:""}
    }
}

export function getSwitchCompany(company){
    let managedByCompany=company.managedBy
    return managedByCompany?.companyName

}
export function getRole(type){
    switch (type){
        case "A":
            return {roleId:"A",roleName:"Administrator"}
        case "S":
            return {roleId:"S",roleName:"Supervisor"}
        case "I":
            return {roleId:"I",roleName:"Installer"}
        case "C":
            return {roleId:"C",roleName:"Customer Support"}
        case "V":
            return {roleId:"V",roleName:"Viewer"}
        default:
            return {roleId:"",roleName:""}
    }

}
export function getStatus(type){
    switch (type){
        case "E":
            return {statusId:"E",statusName:"Enabled"}
        case "D":
            return {statusId:"D",statusName:"Disabled"}
        case "I":
            return {statusId:"I",statusName:"Invited"}
        default:
            return {statusId:"",statusName:""}
    }

}
String.prototype.replaceAt = function(index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
}

export const timeList=["12 AM","1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM",
    "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"]
export function genrateTimeString(days){



    function genrateString(length){
        if(length <=0){
            return ""
        }
        const characterToRepeat = "1";
        const newString = characterToRepeat.repeat(length);
        return newString
    }

    function buildString(string,start,end){
        return string.replaceAt(start,genrateString(end - start))
    }

    let daysLength=days.length
    let timeString=""
    for(let i=0;i<daysLength;i++){
        //24 hourse sting
        let emptyString="000000000000000000000000"
        // get start time
        let startTime1=days[i].firstRange.startTime
        // get end time
        let endTime1=days[i].firstRange.endTime
        // get start time
        let startTime2=days[i].secondRange.startTime
        // get end time
        let endTime2=days[i].secondRange.endTime

        // check both are not empty
        if(startTime1 !==null && endTime1 !==null){
            //get index start time
            let startTimeIndex1=timeList.indexOf(startTime1)
            //get index end time
            let endTimeIndex1=timeList.indexOf(endTime1)
            emptyString=buildString(emptyString,startTimeIndex1,endTimeIndex1)
        }
        if(startTime2 !==null && endTime2 !==null){
            //get index start time
            let startTimeIndex2=timeList.indexOf(startTime2)
            let endTimeIndex2=timeList.indexOf(endTime2)
            let secondString=buildString(emptyString,startTimeIndex2,endTimeIndex2)
            emptyString=secondString
        }

        timeString +=emptyString
    }
    return timeString
}
export function generateStringToTime(daysString=""){
    let timeList=["12 PM","1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 AM",
        "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"]
    let timeArray=[{
        name: "SUN",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {
        name: "MON",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {
        name: "TUE",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {
        name: "WED",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {
        name: "THU",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {
        name: "FRI",
        firstRange: {startTime: null, endTime: null},
        secondRange: {startTime: null, endTime: null}
    }, {name: "SAT", firstRange: {startTime: null, endTime: null}, secondRange: {startTime: null, endTime: null}}
    ]
    if(daysString.length !==168){
        return timeArray
    }
    let emptyFirstRange={firstRange: {startTime: null,endTime: null}}
    let emptySecondRange={secondRange: {startTime: null,endTime: null}}
    function splitStringIntoArray(inputString, chunkSize) {
        if (chunkSize <= 0) {
            return ["Chunk size should be greater than 0"];
        }
        const chunks = [];
        for (let i = 0; i < inputString.length; i += chunkSize) {
            chunks.push(inputString.substr(i, chunkSize));
        }

        return chunks;
    }
    const result = splitStringIntoArray(daysString, 24);
    const resultLength=result.length
    for(let i=0;i<resultLength;i++){
        if(!result[i].includes("1")){
            timeArray[i]={...timeArray[i],...emptyFirstRange,...emptySecondRange}
        }else{
            let firstSectionString=result[i].slice(0,12)
            let secondSectionString=result[i].slice(-12)
            let firstSectionStartTimeIndex=firstSectionString.indexOf("1",0)
            let firstSectionEndTimeIndex=firstSectionString.lastIndexOf("1")+1
            let secondSectionStartTimeIndex=secondSectionString.indexOf("1",0)
            let secondSectionEndTimeIndex=secondSectionString.lastIndexOf("1")+1
            let firstSection={firstRange: {startTime: null,endTime: null}}
            let secondSection={secondRange: {startTime: null,endTime: null}}
            if(firstSectionStartTimeIndex !== -1 && firstSectionEndTimeIndex !==0){
                firstSection={firstRange: {startTime:  timeList[firstSectionStartTimeIndex],endTime: timeList[firstSectionEndTimeIndex]}}
            }
            if(secondSectionEndTimeIndex!==0 && secondSectionStartTimeIndex !==-1){
                secondSection= {secondRange: {startTime: timeList[secondSectionStartTimeIndex+12], endTime:  timeList[secondSectionEndTimeIndex+12]}}
            }
            timeArray[i]={...timeArray[i],...firstSection,...secondSection}
        }

    }
    return timeArray

}


export function AtTimeFormatter(time){
    if(time===null){
        return ""
    }
    const utcTimestamp = moment.utc(time);

    const formattedTime = utcTimestamp.format('YYYY.MM.DD [at] h:mm a');
    // return moment(time).format('YYYY.MM.DD [at] h:mm a')
    return formattedTime
}

export function timeFormatter(time) {
    const {getUserTimeZone}=UserData()
    const date = momentTimezone.utc(time);
    date.tz(getUserTimeZone())
    // const utcMoment = moment.utc(time);
    // moment
    // let date = utcMoment.tz(getUserTimeZone());

    let result = {
        year: date.year(),
        month: formatTime(date.month() + 1), // Adding 1 because months are zero-indexed
        sortMonthName: date.format("MMM"),
        sortDayName: date.format("ddd"),
        day: formatTime(date.date()),
        hours: formatTime(date.format('h')), // 12-hour format
        minutes: formatTime(date.minutes()),
        dayName: date.format("dddd"),
        sortYearName: date.format("YY"),
        ampm: date.format('A') // AM or PM
    };

    return result;
}

function formatTime(time){
    return time.toString()?.length===1?`0${time}`:time
}

// export function timeDifference(start,end ) {
//     const startDate = moment(start);
//     const endDate = moment(end);
//     // const startDate = moment(end);
//     // const endDate = moment(start);
//
// // Calculate the duration between the two dates
//     const duration = moment.duration(endDate.diff(startDate));
//
// // Extract hours, minutes, and seconds
// //     const hours = duration.hours();
// //     const minutes = duration.minutes();
// //     const seconds = duration.seconds();
//     const daysDifference = Math.floor(duration.asDays());
//     const hoursDifference = Math.floor(duration.asHours()) % 24;
//     const minutesDifference = Math.floor(duration.asMinutes()) % 60;
//     const secondsDifference = Math.floor(duration.asSeconds()) % 60;
// // Create an object with the time difference
//
//     const timeD = {
//         hours: hoursDifference,
//         minutes: minutesDifference,
//         seconds: secondsDifference
//     };
//     return timeD
// }
export function timeDifference(end,start ) {
    const timestamp1 = moment(start).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const timestamp2 = moment(end).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    const {getUserTimeZone}=UserData()

    const momentObject1 = momentTimezone.utc(timestamp1);
    const momentObject2 = momentTimezone.utc(timestamp2);
    momentObject1.tz(getUserTimeZone())
    momentObject2.tz(getUserTimeZone())
    // Calculate the difference in milliseconds
    const diffInMilliseconds = momentObject2.diff(momentObject1);

    // Convert the difference to a duration object
    const duration = moment.duration(diffInMilliseconds);

    // Extract individual components of the duration
    // const days = duration.days();
    // const hours = duration.hours();
    // const minutes = duration.minutes();
    // const seconds = duration.seconds();
    const daysDifference = Math.floor(duration.asDays());
    const hoursDifference = Math.floor(duration.asHours()) % 24;
    const minutesDifference = duration.asMinutes() % 60;
    const secondsDifference = Math.floor(duration.asSeconds()) % 60;
// Create an object with the time difference

    const timeD = {
        days:daysDifference,
        hours: hoursDifference,
        minutes: minutesDifference,
        seconds: secondsDifference
    };
    return timeD
}

export function getPreviousWeekDate(timezone) {
    const timeZone =timezone|| 'UTC';
    const today = moment().tz(timeZone);

    const daysAgoDate = today.clone().subtract(7, 'days');
    const formattedToday = today.format('YYYY-MM-DD');
    const formattedDaysAgo = daysAgoDate.format('YYYY-MM-DD');

    return {
        today: formattedToday,
        daysAgo: formattedDaysAgo,
    };
}


export function getPreviousMonth( timezone ) {
    // Set the timezone
    const timeZone = timezone || 'UTC';

    // Get today's date in the specified timezone
    const today = moment().tz(timeZone);

    // Subtract monthsAgo months from today
    const monthsAgoDate = today.clone().subtract(1, 'months');

    // Format the dates
    const formattedToday = today.format('YYYY-MM-DD');
    const formattedMonthsAgo = monthsAgoDate.format('YYYY-MM-DD');

    return {
        today: formattedToday,
        monthsAgo: formattedMonthsAgo,
    };
}
export function getTodayDate(timezone ) {
    // Set the timezone
    const timeZone = timezone || 'UTC';

    // Get today's date in the specified timezone
    const today = moment().tz(timeZone);

    // Format the date
    const formattedToday = today.format('YYYY-MM-DD');

    return formattedToday;
}

export function getALlQueryParams(){
    const params = new URLSearchParams(document.location.search);
    return params
}
